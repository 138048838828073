import { BaseSmartFeedEngine } from "@bloglovin/vue-framework";
import PostPreviewCardLarge from "~/components/smart-feed/post/layouts/PostPreviewCardLarge";
import PostPreviewCardSmall from "~/components/smart-feed/post/layouts/PostPreviewCardSmall";
import SubscriberBillingHistoryTableRow from "~/components/smart-feed/subscriber-billing-history/layouts/SubscriberBillingHistoryTableRow";
import { usePostEntityStore } from "~/stores/smart-feed/entity/post-entity-store";
import { useSubscriberBillingHistoryEntityStore } from "~/stores/smart-feed/entity/subscriber-billing-history-entity-store";

const FanWebSmartFeedEngine = class extends BaseSmartFeedEngine {
  static COMMUNITY_POST = "communityPost";
  static SUBSCRIBER_BILLING_HISTORY = "subscriber_billing_history";

  config = {
    [FanWebSmartFeedEngine.COMMUNITY_POST]: {
      [FanWebSmartFeedEngine.USE_PINIA_STORE]: usePostEntityStore,
      [FanWebSmartFeedEngine.LAYOUT_COMPONENTS]: {
        [FanWebSmartFeedEngine.LAYOUT_CARD_SMALL]: PostPreviewCardSmall,
        [FanWebSmartFeedEngine.LAYOUT_CARD_LARGE]: PostPreviewCardLarge,
        [FanWebSmartFeedEngine.LAYOUT_MASONRY]: PostPreviewCardSmall,
      },
    },
    [FanWebSmartFeedEngine.SUBSCRIBER_BILLING_HISTORY]: {
      [FanWebSmartFeedEngine.USE_PINIA_STORE]:
        useSubscriberBillingHistoryEntityStore,
      [FanWebSmartFeedEngine.LAYOUT_COMPONENTS]: {
        [FanWebSmartFeedEngine.LAYOUT_TABLE]: SubscriberBillingHistoryTableRow,
      },
    },
  };
};

export default FanWebSmartFeedEngine;

import { links } from "%/enums/link-enums";
import FanWebSmartFeedEngine from "~/classes/fanweb-smart-feed-engine";
import { defineSmartFeedPiniaStore } from "@bloglovin/vue-framework";
import { usePublicationStore } from "~/stores/publication-store";
import { useGeneralStore } from "%/stores/general-store";

export const usePostFeedStore = defineSmartFeedPiniaStore("post-feed-store", {
  state: () => ({
    feed: [],
    metadata: {
      hasMore: true,
      lastId: 0,
      publicationId: usePublicationStore().publicationId,
    },
    smartFeedEngine: FanWebSmartFeedEngine,
    isLoading: false,
  }),
  getters: {
    contentApiV1() {
      return useGeneralStore().getLink(links.contentApiV1);
    },
    hasMore() {
      return this.metadata.hasMore;
    },
  },
  actions: {
    reload() {
      this.$reset();
      this.getSmartFeed();
    },
    getSmartFeed() {
      this.apiGet(
        `publication/${this.metadata.publicationId}/posts/smart-feed`,
        {
          last_id: this.metadata.lastId,
        },
        data => {
          this.handleSmartFeedResponse(data, FanWebSmartFeedEngine);
        },
        () => {
          // prevents infinite errors
          this.metadata.hasMore = false;
        },
      );
    },
  },
});

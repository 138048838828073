import dayjs from "dayjs";
const relativeTime = require("dayjs/plugin/relativeTime");
const updateLocale = require("dayjs/plugin/updateLocale");

dayjs.extend(updateLocale);
var config = {
  thresholds: [
    { l: "s", r: 1 },
    { l: "ss", r: 59, d: "second" },
    { l: "m", r: 1 },
    { l: "mm", r: 59, d: "minute" },
    { l: "h", r: 1 },
    { l: "hh", r: 23, d: "hour" },
    { l: "d", r: 1 },
    { l: "dd", r: 29, d: "day" },
  ],
};
dayjs.extend(relativeTime, config);
dayjs.updateLocale("en", {
  monthsShort: [
    "Jan", "Feb", "March", "April", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec",
  ],
  relativeTime: {
    ...dayjs.en.relativeTime,
    past: "%s",
    s: "1s",
    ss: "%ds",
    m: "1m",
    mm: "%dm",
    h: "1h",
    hh: "%dh",
    d: "1d",
    dd: "%dd",
  },
});

export const ENVIRONMENT_PRODUCTION = "production";
export const ENVIRONMENT_STAGING = "staging";

export const injectEnvironment = (location, environment) => {
  switch (environment) {
    case ENVIRONMENT_PRODUCTION:
      break;
    case ENVIRONMENT_STAGING:{
      let relativeLocation = location.split("/");
      relativeLocation.splice(relativeLocation.length - 1, 0, ENVIRONMENT_STAGING);
      location = relativeLocation.join("/");
      break;
    }
    default : break;
  }
  return location;
};

export const timeAgo = timeInUnix => {
  const targetTimestamp = dayjs.unix(timeInUnix);
  const currenTimeStamp = dayjs();
  const differenceInMonths = currenTimeStamp.diff(targetTimestamp, "month");

  if (currenTimeStamp.format("YYYY") !== targetTimestamp.format("YYYY")) {
    return targetTimestamp.format("d MMM YYYY");
  } else if (differenceInMonths < 1) {
    return targetTimestamp.fromNow();
  } else {
    return targetTimestamp.format("d MMM");
  }
};

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.post-preview-actions .row[data-v-15c06f30] {
  align-items: center;
  gap: 8px;
}`, "",{"version":3,"sources":["webpack://./src/FanWeb/scripts/components/smart-feed/post/layouts/PostPreviewCardLarge.vue","webpack://./PostPreviewCardLarge.vue"],"names":[],"mappings":"AAGE;EACE,mBAAA;EACA,QAAA;ACFJ","sourcesContent":["@import \"@bloglovin/vue-component-library/breakpoint-mixin.scss\";\n\n.post-preview-actions {\n  .row {\n    align-items: center;\n    gap: 8px;\n  }\n}\n",".post-preview-actions .row {\n  align-items: center;\n  gap: 8px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

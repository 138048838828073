<template>
  <iStyledCard
    :splash-image="post.thumbnail"
    :title="post.postTitle"
    height="hug"
    :width="$vssMobile ? 'fill' : 350"
    :description="post.text"
    :badge="post.exclusive ? 'Exclusive Content' : ''"
    :description-length="325"
    :splash-image-max-height="250"
    :subtitle="timeAgo(post.timestampContentUpdated)"
    :default-href="navigateToPostUrl"
  >
    <template #footer>
      <iRow class="post-preview-action-container" width="fill" align="between">
        <iColumn width="hug" vertical-align="middle">
          <iRow class="post-preview-actions">
            <template v-if="canVoteOnPosts">
              <iIcon icon="arrow-up" @click.stop="handleVote('up')" />
              <iText>{{ post.voteScore || 0 }}</iText>
              <iIcon icon="arrow-down" @click.stop="handleVote('down')" />
            </template>

            <template v-if="canLikePosts">
              <iIcon
                :icon="+post.likeStatus === 1 ? 'heart-filled' : 'heart'"
                @click.stop="handleLike"
              />
              <iText>{{ post.numLikes || 0 }}</iText>
            </template>

            <iIcon :is-loading="copyingToClipboard" icon="arrow-curved-right" @click.stop="handleShare" />
          </iRow>
        </iColumn>
        <iColumn width="hug" vertical-align="middle" class="post-preview-actions">
          <iIcon icon="ellipsis" />
        </iColumn>
      </iRow>
    </template>
  </iStyledCard>
</template>

<script>
import { postStatus } from "@/enums/post-enums";
import { mapActions, mapState } from "pinia";
import { usePublicationStore } from "~/stores/publication-store";
import { useConfigurationStore } from "~/stores/configuration-store";
import { timeAgo } from "~/helpers/publication-helper";
import { useGeneralStore } from "%/stores/general-store";
import { DEFAULT_USER_AVATAR } from "%/constants/user-constants";
import { useWindowStore } from "@bloglovin/vue-component-library";

export default {
  name: "PostPreviewCardSmall",
  props: {
    entity: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      postStatus,
      copyingToClipboard: false,
      DEFAULT_USER_AVATAR,
    };
  },
  computed: {
    ...mapState(useGeneralStore, [
      "fanUrl",
      "isLoggedIn",
    ]),
    ...mapState(useConfigurationStore, [
      "canLikePosts",
      "canVoteOnPosts",
    ]),
    ...mapState(useWindowStore, [
      "$vssMobile",
    ]),
    post() {
      return this.entity;
    },
    navigateToPostUrl() {
      return this.getRedirectUrl(`/posts/post/${this.post.postId}`);
    },
  },
  created() {
    useWindowStore().init();
  },
  beforeUnmount() {
    useWindowStore().remove();
  },
  mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    const voteType = urlParams.get("voteType");
    const postId = urlParams.get("postId");
    if (this.isLoggedIn && voteType && +postId === this.post.postId) {
      this.vote(voteType);
      urlParams.delete("voteType");
      urlParams.delete("postId");
      const newUrl = `${window.location.pathname}${
        urlParams.toString() ? "?" + urlParams.toString() : ""
      }`;
      window.history.replaceState({}, "", newUrl);
    }
  },
  methods: {
    timeAgo,
    ...mapActions(useGeneralStore, ["getRedirectUrl"]),
    async handleVote(type) {
      if (!this.isLoggedIn) {
        const params = new URLSearchParams({
          voteType: type,
          postId: this.post.postId,
        });
        const url = `${window.location.pathname}?${params.toString()}`;
        const encodedCurrentUri = encodeURIComponent(url);
        window.location.href = `${this.fanUrl}/login?redirect=${encodedCurrentUri}`;
      } else {
        await this.vote(type);
      }
    },
    async vote(type) {
      const postId = this.post.postId;
      if (type === "up") {
        await usePublicationStore().upVotePost(postId);
      } else if (type === "down") {
        await usePublicationStore().downVotePost(postId);
      }
    },
    async handleLike() {
      if (!this.isLoggedIn) {
        const params = new URLSearchParams({
          likeAction: "toggle",
          postId: this.post.postId,
        });
        const url = `${window.location.pathname}?${params.toString()}`;
        const encodedCurrentUri = encodeURIComponent(url);
        window.location.href = `${this.fanUrl}/login?redirect=${encodedCurrentUri}`;
      } else {
        if (+this.post.likeStatus === 1) {
          await usePublicationStore().unlikePost(this.post.postId);
        } else {
          await usePublicationStore().likePost(this.post.postId);
        }
      }
    },
    handleShare(e) {
      e.stopPropagation();
      const shareData = {
        title: this.post.postTitle,
        text: this.post.text?.substring(0, 50) + "...",
        url: this.fanUrl + `/posts/post/${this.post.postId}`,
      };
      if (navigator.share && navigator.canShare(shareData)) {
        navigator.share(shareData);
      } else {
        this.copyingToClipboard = true;
        navigator.clipboard.writeText(shareData.url).then(() => {
          setTimeout(() => {
            this.copyingToClipboard = false;
          }, 1000);
        });
      }
    },
  },
  styleGuide: () => ({
    paddingTop: { "size.spacing": "small" },
  }),
};
</script>

<style scoped lang="scss">
.post-preview {
  &-action-container {
    border-top: 1px solid rgba(0, 0, 0, 0.15);
    width: 100%;
  }

  &-actions {
    padding-top: v-bind('$getStyles.paddingTop');
    gap: 8px;
    align-items: center;
  }
}
</style>

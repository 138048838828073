<template>
  <PageWrapper
    :active-section="SECTION_PUBLICATIONS"
    :active-page="PAGE_MY_ACTIVE_PUBLICATIONS"
    title="Publications"
    :breadcrumbs="breadcrumbs"
    page-name="Publications"
    align="left"
    width="full"
  >
    <iSubHeading>Active Publications</iSubHeading>
    <SmartFeed
      :pinia-store="usePublicationFeedStore"
      default-layout="small-card"
    />
    <iSubHeading>Draft Publications</iSubHeading>
    <SmartFeed
      :pinia-store="useDraftPublicationFeedStore"
      default-layout="small-card"
    />
  </PageWrapper>
</template>

<script>
import { mapState } from "pinia";
import PageWrapper from "@/components/PageWrapper.vue";
import SmartFeed from "@/components/smart-feed/SmartFeed.vue";
import { PAGE_MY_ACTIVE_PUBLICATIONS, SECTION_PUBLICATIONS } from "@/constants/nav-constants";
import { usePublicationStore } from "@/stores/publication-store";
import { usePublicationFeedStore } from "@/stores/smart-feed/feed/publication-feed-store";
import { useDraftPublicationFeedStore } from "@/stores/smart-feed/feed/draft-publication-feed-store";

export default {
  name: "MyActivePublications",
  components: {
    SmartFeed,
    PageWrapper,
  },
  data() {
    return {
      SECTION_PUBLICATIONS,
      PAGE_MY_ACTIVE_PUBLICATIONS,
    };
  },
  computed: {
    ...mapState(usePublicationStore, [
      "orgId",
    ]),
    breadcrumbs() {
      return [
        { url: "/", label: "Home" },
        { url: `/publication/org/${this.orgId}`, label: "My Publications" },
      ];
    },
  },
  methods: {
    usePublicationFeedStore,
    useDraftPublicationFeedStore,
  },
};
</script>

import { defineFeedEntityPiniaStore } from "@bloglovin/vue-framework";

export const useMyPostEntityStore = defineFeedEntityPiniaStore(
  "my-post-entity-store",
  {
    state: () => ({}),
    getters: {},
    actions: {},
  },
);

<template>
  <iStyledCard
    :splash-image="post.thumbnail"
    :title="post.postTitle"
    height="hug"
    :badge="post.exclusive ? 'Exclusive Content' : ''"
    width="fill"
    :description="post.text"
    :description-length="325"
    :splash-image-max-height="250"
    :subtitle="timeAgo(post.timestampContentUpdated)"
    :default-href="navigateToPostUrl"
  >
    <template #footer>
      <iColumn class="post-preview-actions">
        <iRow>
          <template v-if="canVoteOnPosts">
            <iIcon icon="arrow-up" @click.stop="handleVote('up')" />
            <iText>{{ post.voteScore || 0 }}</iText>
            <iIcon icon="arrow-down" @click.stop="handleVote('down')" />
          </template>
          <template v-if="canLikePosts">
            <iIcon
              :icon="+post.likeStatus === 1 ? 'heart-filled' : 'heart'"
              @click.stop="handleLike"
            />
            <iText>{{ post.numLikes || 0 }}</iText>
          </template>
          <iIcon :is-loading="copyingToClipboard" icon="arrow-curved-right" @click.stop="handleShare" />
        </iRow>
      </iColumn>
    </template>
  </iStyledCard>
</template>

<script>
import { mapActions, mapState } from "pinia";
import { timeAgo } from "~/helpers/publication-helper";
import { useGeneralStore } from "%/stores/general-store";
import { usePublicationStore } from "~/stores/publication-store";
import { useConfigurationStore } from "~/stores/configuration-store";

export default {
  name: "CommunityPostPreview",
  props: {
    entity: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      voteType: null,
      copyingToClipboard: false,
    };
  },
  computed: {
    ...mapState(useGeneralStore, ["isLoggedIn", "fanUrl"]),
    navigateToPostUrl() {
      return this.getRedirectUrl(`/posts/post/${this.post.postId}`);
    },
    ...mapState(useConfigurationStore, [
      "canLikePosts",
      "canVoteOnPosts",
    ]),
    post() {
      return this.entity;
    },
  },
  mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    const voteType = urlParams.get("voteType");
    const postId = urlParams.get("postId");
    if (this.isLoggedIn && voteType && +postId === this.post.postId) {
      this.vote(voteType);
      urlParams.delete("voteType");
      urlParams.delete("postId");
      const newUrl = `${window.location.pathname}${
        urlParams.toString() ? "?" + urlParams.toString() : ""
      }`;
      window.history.replaceState({}, "", newUrl);
    }
  },
  methods: {
    timeAgo,
    ...mapActions(useGeneralStore, [
      "redirect",
      "getRedirectUrl",
    ]),
    async handleVote(type) {
      if (!this.isLoggedIn) {
        const params = new URLSearchParams({
          voteType: type,
          postId: this.post.postId,
        });
        const url = `${window.location.pathname}?${params.toString()}`;
        const encodedCurrentUri = encodeURIComponent(url);
        window.location.href = `${this.fanUrl}/login?redirect=${encodedCurrentUri}`;
      } else {
        await this.vote(type);
      }
    },
    async vote(type) {
      const postId = this.post.postId;
      if (type === "up") {
        await usePublicationStore().upVotePost(postId);
      } else if (type === "down") {
        await usePublicationStore().downVotePost(postId);
      }
    },
    async handleLike() {
      if (!this.isLoggedIn) {
        const params = new URLSearchParams({
          likeAction: "toggle",
          postId: this.post.postId,
        });
        const url = `${window.location.pathname}?${params.toString()}`;
        const encodedCurrentUri = encodeURIComponent(url);
        window.location.href = `${this.fanUrl}/login?redirect=${encodedCurrentUri}`;
      } else {
        if (+this.post.likeStatus === 1) {
          await usePublicationStore().unlikePost(this.post.postId);
        } else {
          await usePublicationStore().likePost(this.post.postId);
        }
      }
    },
    navigateToPost() {
      this.redirect(`/posts/post/${this.post.postId}`);
    },
    handleShare(e) {
      e.stopPropagation();
      const shareData = {
        title: this.post.postTitle,
        text: this.post.text?.substring(0, 50) + "...",
        url: this.fanUrl + `/posts/post/${this.post.postId}`,
      };
      if (navigator.share && navigator.canShare(shareData)) {
        navigator.share(shareData);
      } else {
        this.copyingToClipboard = true;
        navigator.clipboard.writeText(shareData.url).then(() => {
          setTimeout(() => {
            this.copyingToClipboard = false;
          }, 1000);
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.post-preview-actions {
  .row {
    align-items: center;
    gap: 8px;
  }
}
</style>

import { useAffiliateLinkEntityStore } from "@/stores/smart-feed/entity/affiliate-link-entity-store";
import { useCommunityPublicationEntityStore } from "@/stores/smart-feed/entity/community-publication-entity-store";
import { useOrgApplicationEntityStore } from "@/stores/smart-feed/entity/org-application-entity-store";
import { useOrgEntityStore } from "@/stores/smart-feed/entity/org-entity-store";
import { usePublicationCollaboratorEntityStore } from "@/stores/smart-feed/entity/publication-collaborator-entity-store";
import { usePublicationEntityStore } from "@/stores/smart-feed/entity/publication-entity-store";
import { useUserEntityStore } from "@/stores/smart-feed/entity/user-entity-store";
import { BaseSmartFeedEngine } from "@bloglovin/vue-framework";
import { useMyPostEntityStore } from "@/stores/smart-feed/entity/my-post-entity-store";
import { useCommunityPostEntityStore } from "@/stores/smart-feed/entity/community-post-entity-store";
import { usePublicationUsersEntityStore } from "@/stores/smart-feed/entity/publication-users-entity-store";
import { useMyPostVariantEntityStore } from "@/stores/smart-feed/entity/my-post-variant-entity-store";
import { useMyPublicationPostEntityStore } from "@/stores/smart-feed/entity/my-publication-post-entity-store";
import { useFeedGroupEntityStore } from "@/stores/smart-feed/entity/feed-group-entity-store";
import { useFeedGroupBlogEntityStore } from "@/stores/smart-feed/entity/feed-group-blog-entity-store";
import { useMyPublicationCollaboratorsEntityStore } from "@/stores/smart-feed/entity/my-publication-collaborators-entity-store";

const CreatorSmartFeedEngine = class extends BaseSmartFeedEngine {
  static AFFILIATE_LINK = "affiliateLink";
  static PUBLICATION = "publication";
  static PUBLICATION_POST = "publicationPost";
  static COMMUNITY_PUBLICATION = "community_publication";
  static PUBLICATION_COLLABORATOR = "publication_collaborator";
  static ORG = "org";
  static USER = "user";
  static ORG_APPLICATION = "org_application";
  static MY_POST = "myPost";
  static MY_POST_VARIANT = "myPostVariant";
  static COMMUNITY_POST = "communityPost";
  static PUBLICATION_USERS = "publicationUsers";
  static MY_PUBLICATION_COLLABORATORS = "myPublicationCollaborators";

  static FEED_GROUP = "feedGroup";
  static FEED_GROUP_BLOG = "feedGroupBlog";

  config = {
    [CreatorSmartFeedEngine.AFFILIATE_LINK]: {
      [CreatorSmartFeedEngine.USE_PINIA_STORE]: useAffiliateLinkEntityStore,
      [CreatorSmartFeedEngine.LAYOUT_COMPONENTS]: {
        [CreatorSmartFeedEngine.LAYOUT_CARD_SMALL]: import("@/components/smart-feed/affiliate-link/layouts/AffiliateLinkCardSmall"),
        [CreatorSmartFeedEngine.LAYOUT_CARD_LARGE]: import("@/components/smart-feed/affiliate-link/layouts/AffiliateLinkCardLarge"),
        [CreatorSmartFeedEngine.LAYOUT_LIST]: import("@/components/smart-feed/affiliate-link/layouts/AffiliateLinkListItem"),
        [CreatorSmartFeedEngine.LAYOUT_TABLE]: import("@/components/smart-feed/affiliate-link/layouts/AffiliateLinkTableRow"),
      },
    },
    [CreatorSmartFeedEngine.PUBLICATION_USERS]: {
      [CreatorSmartFeedEngine.USE_PINIA_STORE]: usePublicationUsersEntityStore,
      [CreatorSmartFeedEngine.LAYOUT_COMPONENTS]: {
        [CreatorSmartFeedEngine.LAYOUT_TABLE]: import("@/components/smart-feed/publication-users/PublicationUsersTableRow"),
      },
    },
    [CreatorSmartFeedEngine.PUBLICATION]: {
      [CreatorSmartFeedEngine.USE_PINIA_STORE]: usePublicationEntityStore,
      [CreatorSmartFeedEngine.LAYOUT_COMPONENTS]: {
        [CreatorSmartFeedEngine.LAYOUT_CARD_SMALL]: import("@/components/smart-feed/publication/layouts/PublicationCardSmall"),
      },
    },
    [CreatorSmartFeedEngine.COMMUNITY_PUBLICATION]: {
      [CreatorSmartFeedEngine.USE_PINIA_STORE]:
        useCommunityPublicationEntityStore,
      [CreatorSmartFeedEngine.LAYOUT_COMPONENTS]: {
        [CreatorSmartFeedEngine.LAYOUT_CARD_SMALL]: import("@/components/smart-feed/publication/layouts/CommunityPublicationCardSmall"),
      },
    },
    [CreatorSmartFeedEngine.PUBLICATION_COLLABORATOR]: {
      [CreatorSmartFeedEngine.USE_PINIA_STORE]:
        usePublicationCollaboratorEntityStore,
      [CreatorSmartFeedEngine.LAYOUT_COMPONENTS]: {
        [CreatorSmartFeedEngine.LAYOUT_CARD_SMALL]: import("@/components/smart-feed/org-application/layouts/PublicationCollaboratorCardSmall.vue"),
      },
    },
    [CreatorSmartFeedEngine.ORG]: {
      [CreatorSmartFeedEngine.USE_PINIA_STORE]: useOrgEntityStore,
      [CreatorSmartFeedEngine.LAYOUT_COMPONENTS]: {
        [CreatorSmartFeedEngine.LAYOUT_CARD_SMALL]: import("@/components/smart-feed/org/layouts/OrgCardSmall"),
      },
    },
    [CreatorSmartFeedEngine.USER]: {
      [CreatorSmartFeedEngine.USE_PINIA_STORE]: useUserEntityStore,
      [CreatorSmartFeedEngine.LAYOUT_COMPONENTS]: {
        [CreatorSmartFeedEngine.LAYOUT_TABLE]: import("@/components/smart-feed/user/layouts/UserTableRow"),
      },
    },
    [CreatorSmartFeedEngine.ORG_APPLICATION]: {
      [CreatorSmartFeedEngine.USE_PINIA_STORE]: useOrgApplicationEntityStore,
      [CreatorSmartFeedEngine.LAYOUT_COMPONENTS]: {
        [CreatorSmartFeedEngine.LAYOUT_CARD_SMALL]:
            import("@/components/smart-feed/org-application/layouts/PublicationCollaboratorCardSmall.vue"),
      },
    },
    [CreatorSmartFeedEngine.MY_POST]: {
      [CreatorSmartFeedEngine.USE_PINIA_STORE]: useMyPostEntityStore,
      [CreatorSmartFeedEngine.LAYOUT_COMPONENTS]: {
        [CreatorSmartFeedEngine.LAYOUT_TABLE]: import("@/components/smart-feed/posts/layouts/MyPostTableRow"),
      },
    },
    [CreatorSmartFeedEngine.PUBLICATION_POST]: {
      [CreatorSmartFeedEngine.USE_PINIA_STORE]: useMyPublicationPostEntityStore,
      [CreatorSmartFeedEngine.LAYOUT_COMPONENTS]: {
        [CreatorSmartFeedEngine.LAYOUT_TABLE]: import("@/components/smart-feed/posts/layouts/MyPublicationPostTableRow"),
      },
    },
    [CreatorSmartFeedEngine.MY_POST_VARIANT]: {
      [CreatorSmartFeedEngine.USE_PINIA_STORE]: useMyPostVariantEntityStore,
      [CreatorSmartFeedEngine.LAYOUT_COMPONENTS]: {
        [CreatorSmartFeedEngine.LAYOUT_TABLE]: import("@/components/smart-feed/my-post-variants/layouts/MyPostVariantTableRow"),
      },
    },
    [CreatorSmartFeedEngine.COMMUNITY_POST]: {
      [CreatorSmartFeedEngine.USE_PINIA_STORE]: useCommunityPostEntityStore,
      [CreatorSmartFeedEngine.LAYOUT_COMPONENTS]: {
        [CreatorSmartFeedEngine.LAYOUT_MASONRY]: import("@/components/smart-feed/posts/layouts/CommunityPostCardSmall"),
      },
    },
    [CreatorSmartFeedEngine.FEED_GROUP]: {
      [CreatorSmartFeedEngine.USE_PINIA_STORE]: useFeedGroupEntityStore,
      [CreatorSmartFeedEngine.LAYOUT_COMPONENTS]: {
        [CreatorSmartFeedEngine.LAYOUT_CARD_SMALL]: import("@/components/smart-feed/feed-group/layouts/FeedGroupCardSmall"),
      },
    },
    [CreatorSmartFeedEngine.FEED_GROUP_BLOG]: {
      [CreatorSmartFeedEngine.USE_PINIA_STORE]: useFeedGroupBlogEntityStore,
      [CreatorSmartFeedEngine.LAYOUT_COMPONENTS]: {
        [CreatorSmartFeedEngine.LAYOUT_CARD_SMALL]: import("@/components/smart-feed/feed-group/layouts/FeedGroupBlogCardSmall"),
      },
    },
    [CreatorSmartFeedEngine.MY_PUBLICATION_COLLABORATORS]: {
      [CreatorSmartFeedEngine.USE_PINIA_STORE]: useMyPublicationCollaboratorsEntityStore,
      [CreatorSmartFeedEngine.LAYOUT_COMPONENTS]: {
        [CreatorSmartFeedEngine.LAYOUT_TABLE]: import("@/components/smart-feed/my-publication-collaborators/layouts/MyPublicationCollaboratorsTableRow"),
      },
    },
  };
};

export default CreatorSmartFeedEngine;

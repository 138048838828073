import CreatorSmartFeedEngine from "@/classes/creator-smart-feed-engine";
import { defineSmartFeedPiniaStore } from "@bloglovin/vue-framework";
import { usePublicationStore } from "@/stores/publication-store";

export const usePublicationPostFeedStore = defineSmartFeedPiniaStore("publication-post-feed-store", {
  state: () => ({
    entityType: CreatorSmartFeedEngine.PUBLICATION_POST,
    feed: [],
    metadata: {
      hasMore: true,
      lastId: 0,
      publicationId: usePublicationStore().publicationId,
    },
    smartFeedEngine: CreatorSmartFeedEngine,
    isLoading: false,
    tableFields: [
      { key: "postTitle", label: "Title" },
      { key: "author", label: "Author" },
      { key: "status", label: "Status" },
      { key: "variants", label: "Variants" },
      { key: "timestampUpdated", label: "Edited" },
      { key: "timestampCreated", label: "Created" },
      { key: "actions", label: "" },
    ],
  }),
  getters: {
    hasMore() {
      return this.metadata.hasMore;
    },
  },
  actions: {
    resetFeed() {
      this.metadata.lastId = 0;
      this.metadata.hasMore = true;
      this.feed = [];
    },
    getSmartFeed() {
      this.apiGet(
        `/publication/${this.metadata.publicationId}/post/smart-feed`,
        {
          last_id: this.metadata.lastId,
        },
        data => this.handleSmartFeedResponse(data, CreatorSmartFeedEngine),
        () => this.metadata.hasMore = false,
      );
    },
  },
});

<template>
  <iRow :wrap="$vssTablet ? 'nowrap' : 'wrap'" align="right" vertical-align="middle">
    <iColumn width="hug">
      <iButton
        v-if="post.variant_count > 1"
        class="text-btn"
        variant="text"
        @click="showShowPostVariantsModal = true;"
      >
        1 of {{ post.variant_count }} variants
      </iButton>
      <iButton
        v-else
        class="text-btn"
        variant="text"
        @click="showCreateVariantsModal = true;"
      >
        No variants
      </iButton>
    </iColumn>
    <iColumn width="hug">
      <iButton
        v-if="!post.publication_name"
        class="text-btn"
        variant="text"
        @click="triggerPublicationAssignModal"
      >
        Assign to publication
      </iButton>
      <iText v-else>
        {{ post.publication_name }}
      </iText>
    </iColumn>
    <iRow gap="large" width="hug" wrap="nowrap">
      <iRow
        gap="extraSmall"
        vertical-align="middle"
        width="hug"
        wrap="nowrap"
      >
        <iCheckbox
          v-model="isPostExclusive"
          :is-loading="updatePostExclusivityIsLoading"
          @change="updatePostExclusivityFlag"
        />
        <iColumn v-tooltip="exclusiveCheckboxTooltip" width="hug">
          <iIcon icon="dollar" variant="branded" />
        </iColumn>
      </iRow>
      <iRow
        gap="extraSmall"
        vertical-align="middle"
        width="hug"
        wrap="nowrap"
      >
        <iCheckbox
          v-model="isPostInSyndicationMarketplace"
          :is-loading="updatePostInSyndicationMarketplaceIsLoading"
          @change="updateSyndicationMarketplaceFlag"
        />
        <iColumn v-tooltip="syndicationMarketplaceCheckboxTooltip" width="hug">
          <iIcon icon="shopping-bag" variant="branded" />
        </iColumn>
      </iRow>
    </iRow>
    <iColumn vertical-align="middle" width="hug">
      <iButton class="text-btn" variant="text" @click="triggerHashtagsModal = true">
        <iRow gap="extraSmall" vertical-align="middle" wrap="nowrap">
          <iText>+ Hashtags</iText>
          <iText v-if="hashtags.length">
            ({{ hashtags.length }})
          </iText>
        </iRow>
      </iButton>
    </iColumn>
    <iColumn width="hug">
      <iText :wrap="false" variant="branded">
        {{ savingStatusArray[savingStatus] }}
      </iText>
    </iColumn>
  </iRow>
  <iEditor
    ref="editor"
    :update-post-is-loading="updatePostIsLoading"
    :upload-post-image="uploadFunction"
    height="fill"
    overflow="auto"
    variant="medium"
    width="fill"
    @input="saveWithDebounce"
  >
    <template #above-editor>
      <iSpace />
      <iRow width="fill" wrap="nowrap">
        <iTextEditInline
          id="title"
          ref="title"
          :model-value="title"
          :show-icon-on-edit="false"
          class="native-post-title"
          name="title"
          placeholder="Title"
          type="text"
          width="fill"
          @update:model-value="title = $event; saveTitleWithDebounce()"
        />
      </iRow>
    </template>
  </iEditor>
  <iModal
    v-if="showEditPostConfirmation"
    :close-on-backdrop="false"
    :close-on-escape="false"
    :close-on-secondary="false"
    :primary-action-button-loading="updatePostIsLoading"
    :width="600"
    primary-action-button-label="Continue"
    title="Edit Post"
    @close="closeEditPostConfirmation"
    @click:primary="goToEditPost"
    @click:secondary="closeEditPostConfirmation"
  >
    <template #body>
      <iParagraph>
        Your post status will be changed to Draft/Unpublished. Are you sure you want to edit this post?
      </iParagraph>
    </template>
  </iModal>

  <iModal
    v-if="assignPublicationModalOpen"
    :close-on-backdrop="false"
    :close-on-escape="false"
    :close-on-secondary="false"
    :primary-action-button-loading="updatePostIsLoading"
    :width="600"
    primary-action-button-label="Continue"
    title="Assign Publication"
    @close="assignPublicationModalOpen = false"
    @click:primary="assignPublication"
    @click:secondary="assignPublicationModalOpen = false"
  >
    <template #body>
      <iColumn max-width-only>
        <iSelect
          v-model="postPublication"
          :items="myPublications"
          label="Select a publication"
          label-field="name"
          value-field="publication_id"
          width="fill"
        />
      </iColumn>
    </template>
  </iModal>
  <template v-if="post">
    <ShowVariantsModal
      :parent-id="post.variant_of_post_id"
      :post-id="post.post_id"
      :post-title="post.post_title"
      :show="showShowPostVariantsModal"
      @close="showShowPostVariantsModal = false"
    />
    <CreateVariantsModal
      :post-id="post.post_id"
      :post-title="post.post_title"
      :show="showCreateVariantsModal"
      @close="showCreateVariantsModal = false"
    />
    <iModal
      v-if="showEditPostConfirmation"
      :close-on-backdrop="false"
      :close-on-escape="false"
      :close-on-secondary="false"
      :primary-action-button-loading="updatePostIsLoading"
      :width="600"
      primary-action-button-label="Continue"
      title="Edit Post"
      @close="closeEditPostConfirmation"
      @click:primary="forceDraftStatusConfirmation"
      @click:secondary="closeEditPostConfirmation"
    >
      <template #body>
        <iParagraph>
          Your post is currently live. If you want to continue editing, your post will be changed to draft.
        </iParagraph>
      </template>
    </iModal>
  </template>

  <iModal
    v-if="triggerHashtagsModal"
    :close-on-backdrop="false"
    :close-on-escape="false"
    :close-on-secondary="false"
    :secondary-action-button-loading="updatePostIsLoading"
    :show-primary-action-button="false"
    :width="600"
    secondary-action-button-label="Close"
    title="Hashtags"
    @close="triggerHashtagsModal = false"
    @click:secondary="triggerHashtagsModal = false"
  >
    <template #body>
      <iRow>
        <iHashtagInput
          v-model="hashtags"
          :validation="validateHashtag"
          hashtag-value-key="hashtag"
          @submit:hashtag="addHashtag"
          @delete:hashtag="removeHashtag"
        />
      </iRow>
    </template>
  </iModal>
  <template v-if="post">
    <ShowVariantsModal
      :parent-id="post.variant_of_post_id"
      :post-id="post.post_id"
      :show="showShowPostVariantsModal"
      @close="showShowPostVariantsModal = false"
    />
    <CreateVariantsModal
      :post-id="post.post_id"
      :post-title="post.post_title"
      :show="showCreateVariantsModal"
      @close="showCreateVariantsModal = false"
    />
  </template>
</template>

<script>
import { mapActions, mapState, mapWritableState } from "pinia";
import { usePublicationStore } from "@/stores/publication-store";
import { postStatus } from "@/enums/post-enums";
import { getRelativeTimeString, useWindowStore } from "@bloglovin/vue-component-library";
import ShowVariantsModal from "@/components/publication/ShowVariantsModal";
import CreateVariantsModal from "@/components/publication/CreateVariantsModal";

export default {
  name: "StoryEditorComponent",
  components: {
    CreateVariantsModal,
    ShowVariantsModal,
  },
  props: {
    postData: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    saveWhileActive: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      isPostExclusive: false,
      isPostInSyndicationMarketplace: false,
      showEditPostConfirmation: false,
      debounce: null,
      postLastUpdated: null,
      title: "",
      triggerHashtagsModal: false,
      assignPublicationModalOpen: false,
      showShowPostVariantsModal: false,
      showCreateVariantsModal: false,
      initialLoad: 0,
      savingStatus: 0,
      postPublication: null,
      forceDraftStatus: false,
      savingStatusArray: [
        "Changes Saved",
        "Unsaved Changes",
        "Saving Changes",
        "Error Saving Changes",
      ],
    };
  },
  computed: {
    ...mapState(useWindowStore, [
      "$vssTablet",
    ]),
    ...mapState(usePublicationStore, [
      "publicationId",
      "uploadPostImageLoading",
      "loadingAddHashtagToPost",
      "errorAddHashtagToPost",
      "updatePostExclusivityIsLoading",
      "updatePostInSyndicationMarketplaceIsLoading",
      "myPublications",
    ]),
    post() {
      if (Object.keys(this.postData).length === 0) {
        return this.postStoreData;
      }
      return this.postData;
    },
    ...mapState(usePublicationStore, {
      postStoreData: "post",
    }),
    ...mapWritableState(usePublicationStore, ["updatePostIsLoading"]),
    exclusiveCheckboxTooltip() {
      return "You can mark this content as exclusive. This will make it only visible to active subscribers.";
    },
    syndicationMarketplaceCheckboxTooltip() {
      return "You can share this content in the syndication marketplace to allow other creators to add your content to their publications. " +
          "You will still retain post ownership and share revenue earned from those publications";
    },
    hashtags: {
      get() {
        return this.post.hashtags || [];
      },
      set(value) {
        this.post.hashtags = value;
      },
    },
  },
  mounted() {
    if (this.post.post_title) {
      this.title = this.post.post_title || "New Draft Story";
    }
    this.$refs.editor.editorRef.setContent(this.convertPostSegmentsToTiptapContent(this.post.segments));
    this.isPostExclusive = !!this.post.exclusive;
    this.isPostInSyndicationMarketplace = !!this.post.in_syndication_marketplace;
    if (this.title) {
      this.$refs.editor.editorRef.focusEditor();
    } else {
      document.getElementById("title").firstElementChild.click();
      this.$nextTick(() => {
        document.getElementById("title").firstElementChild.click();
      });
    }
    useWindowStore().init();
  },
  beforeUnmount() {
    useWindowStore().remove();
  },
  methods: {
    ...mapActions(usePublicationStore, [
      "uploadPostImage",
      "updatePost",
      "addHashtagsToPost",
      "removeHashtagFromPost",
      "updatePostExclusiveContentFlag",
      "updatePostSyndicationMarketplaceFlag",
      "updatePostTitle",
      "assignPostToPublication",
    ]),
    triggerPublicationAssignModal() {
      if (this.post.publication_id && this.post.publication_id > 0) {
        return;
      }
      this.assignPublicationModalOpen = true;
    },
    convertPostSegmentsToTiptapContent(postSegments) {
      // Define the output structure
      const tiptapContent = [];

      // Iterate through each post segment
      postSegments.forEach(segment => {
        const content = JSON.parse(segment.post_segment_content); // Parse the content of each segment

        // Handle rich text segments
        if (segment.post_segment_type === "SEGMENT_TYPE_RICH_TEXT_01") {
          if (content.bodyText) {
            let nodeContent = [];
            let currentIndex = 0;

            // Apply styles based on ranges (e.g., bold, italic, links)
            content.ranges.forEach(range => {
              if (range.start > currentIndex) {
                // Add the normal text before the styled portion
                nodeContent.push({
                  type: "text",
                  text: content.bodyText.slice(currentIndex, range.start),
                });
              }

              // Create the styled text based on the range's style
              let styledText = {
                type: "text",
                text: content.bodyText.slice(range.start, range.end),
                marks: [],
              };

              // Add appropriate marks for the text
              if (range.style === "BOLD") {
                styledText.marks.push({ type: "bold" });
              }
              if (range.style === "ITALIC") {
                styledText.marks.push({ type: "italic" });
              }
              if (range.style === "LINK") {
                styledText.marks.push({
                  type: "link",
                  attrs: { href: range.url, target: "_blank" },
                });
              }

              nodeContent.push(styledText);
              currentIndex = range.end;
            });

            // Add any remaining text after the last range
            if (currentIndex < content.bodyText.length) {
              nodeContent.push({
                type: "text",
                text: content.bodyText.slice(currentIndex),
              });
            }

            // Push the paragraph with all styled content
            tiptapContent.push({
              type: "paragraph",
              content: nodeContent,
            });
          } else {
            // Add empty paragraph for empty bodyText
            tiptapContent.push({
              type: "paragraph",
              content: [],
            });
          }
        }

        // Handle image segments
        if (segment.post_segment_type === "SEGMENT_TYPE_IMAGE_01") {
          if (content.imageUrl) {
            tiptapContent.push({
              type: "CustomImage",
              attrs: {
                src: content.imageUrl,
                width: content.width || "100%", // Default to 100% if width is not provided
                alt: "", // Set an empty alt attribute, adjust as needed
              },
            });
          }
        }
      });

      // Return the final structured content that Tiptap can understand
      return {
        type: "doc",
        content: tiptapContent,
      };
    },
    closeEditPostConfirmation() {
      this.showEditPostConfirmation = false;
    },
    forceDraftStatusConfirmation() {
      this.showEditPostConfirmation = false;
      this.forceDraftStatus = true;
    },
    saveWithDebounce(content) {
      this.handleBeforeSave(() => {
        if (this.debounce) {
          clearTimeout(this.debounce);
        }
        this.debounce = setTimeout(() => {
          this.savingStatus = 2;
          this.submitPost(this.title, content, postStatus.DRAFT, () => {
            this.savingStatus = 0;
          }, () => {
            this.savingStatus = 3;
          });
        }, 1000);
      });
    },
    saveTitleWithDebounce() {
      this.handleBeforeSave(() => {
        this.savingStatus = 1;
        this.updatePostIsLoading = true;
        if (this.debounce) {
          clearTimeout(this.debounce);
        }
        this.debounce = setTimeout(() => {
          this.savingStatus = 2;
          this.updatePostTitle(this.post.post_id, this.title, () => {
            this.savingStatus = 0;
          }, () => {
            this.savingStatus = 3;
          });
        }, 1000);
      });
    },
    addHashtag(newTag) {
      this.savingStatus = 2;
      this.addHashtagsToPost([newTag], this.post.post_id, () => {
        this.savingStatus = 3;
        this.hashtags.pop();
      }, () => {
        this.savingStatus = 0;
      });
    },
    handleBeforeSave(process = () => {
    }) {
      if (this.initialLoad < 1) {
        this.initialLoad++;
        return;
      }
      if (this.post.status === postStatus.ACTIVE && !this.saveWhileActive && !this.forceDraftStatus) {
        this.showEditPostConfirmation = true;
        this.$refs.editor.editorRef.setContent(this.convertPostSegmentsToTiptapContent(this.post.segments));
        if (this.post.post_title) {
          this.$refs.title.setValue(this.post.post_title);
        }
        return;
      }
      process();
    },
    removeHashtag({ el, index }) {
      this.savingStatus = 2;
      this.removeHashtagFromPost(el.hashtag_id, this.post.post_id, () => {
        this.savingStatus = 3;
        this.hashtags.splice(index, 0, el);
      }, () => {
        this.savingStatus = 0;
      });
    },
    validateHashtag(value) {
      const result = /^(#)?[a-zA-Z0-9_]+$/.test(value);
      if (!result) {
        return "Hashtag must be alphanumeric and can only contain underscores.";
      }
      return true;
    },
    async uploadFunction(file) {
      return await this.uploadPostImage(file);
    },
    submitPost(title, content, status = "", onComplete = () => {
    }, onError = () => {
    }) {
      if (this.post.status !== postStatus.ACTIVE) {
        this.updatePostIsLoading = false;
      }
      this.updatePost(this.post.post_id, title, content, status || this.post.status, onComplete, onError);
    },
    updatePostExclusivityFlag() {
      this.savingStatus = 2;
      this.updatePostExclusiveContentFlag(this.post.post_id, this.isPostExclusive, () => {
        this.savingStatus = 0;
      }, () => {
        this.savingStatus = 3;
      });
    },
    updateSyndicationMarketplaceFlag() {
      this.savingStatus = 2;
      this.updatePostSyndicationMarketplaceFlag(this.post.post_id, this.isPostInSyndicationMarketplace, () => {
        this.savingStatus = 0;
      }, () => {
        this.savingStatus = 3;
      });
    },
    assignPublication() {
      this.savingStatus = 2;
      this.assignPublicationModalOpen = false;
      this.assignPostToPublication(this.post.post_id, this.postPublication.publication_id, () => {
        this.savingStatus = 0;
        this.post.publication_id = this.postPublication.publication_id;
        this.postPublication = null;
        this.post.publication_name = this.myPublications.find(p => p.publication_id === this.post.publication_id).name;
      }, () => {
        this.savingStatus = 3;
      });
    },
    getRelativeTimeString,
  },
};
</script>

<style lang="scss" scoped>
:deep(toptap.ProseMirror) {
  height: 100%;
  box-sizing: border-box;
  cursor: text;
}

.native-post-title {
  :deep(.i-text.text-edit) {
    width: 100%;
    font-size: 26px;
    font-weight: 700;
  }
}
</style>

import { useGeneralStore } from "%/stores/general-store";
import CreatorSmartFeedEngine from "@/classes/creator-smart-feed-engine";
import { defineSmartFeedPiniaStore } from "@bloglovin/vue-framework";

export const usePublicationFeedStore = defineSmartFeedPiniaStore("publication-feed-store", {
  state: () => ({
    entityType: CreatorSmartFeedEngine.PUBLICATION,
    feed: [],
    metadata: {
      hasMore: true,
      lastId: 0,
      publicationOrgId: useGeneralStore().orgId,
    },
    isLoading: false,
    smartFeedEngine: CreatorSmartFeedEngine,
  }),
  getters: {
    hasMore() {
      return this.metadata.hasMore;
    },
  },
  actions: {
    getSmartFeed() {
      const params = {
        last_id: this.metadata.lastId,
        status: "active",
      };
      this.apiGet(
        `/publication/org/${this.metadata.publicationOrgId}/smart-feed/status`,
        params,
        data => {
          this.handleSmartFeedResponse(data, CreatorSmartFeedEngine);
        },
        () => {
          this.metadata.hasMore = false;
        },
      );
    },
  },
});

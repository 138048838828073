<template>
  <tr>
    <td v-for="field in fields" :key="field.key">
      <slot :name="`td(${field.key})`" :entity="entity" :field="field">
        {{ entity[field.key] }}
      </slot>
    </td>
  </tr>
</template>

<script>
export default {
  name: "SmartFeedTableRow",
  props: {
    fields: {
      type: Object,
      required: true,
    },
    entity: {
      type: Object,
      required: true,
    },
  },
};
</script>

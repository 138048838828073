import CreatorSmartFeedEngine from "@/classes/creator-smart-feed-engine";
import { defineSmartFeedPiniaStore } from "@bloglovin/vue-framework";
import { usePublicationStore } from "@/stores/publication-store";

export const useMyPublicationCollaboratorsFeedStore = defineSmartFeedPiniaStore(
  "my-publication-collaborators-feed-store",
  {
    state: () => ({
      entityType: CreatorSmartFeedEngine.MY_PUBLICATION_COLLABORATORS,
      feed: [],
      metadata: {
        hasMore: true,
        lastId: 0,
        publicationId: usePublicationStore().publicationId,
      },
      smartFeedEngine: CreatorSmartFeedEngine,
      tableFields: [
        { key: "name", label: "Name", width: "20%" },
        { key: "email", label: "Email", width: "30%" },
        { key: "role", label: "Role", width: "20%" },
        { key: "status", label: "Status", width: "20%" },
        { key: "actions", label: "", width: "10%" },
      ],
      isLoading: false,
    }),
    getters: {
      hasMore() {
        return this.metadata.hasMore;
      },
    },
    actions: {
      getSmartFeed() {
        this.apiGet(
          `/publication/${this.metadata.publicationId}/collaborators/smart-feed`,
          {
            last_id: this.metadata.lastId,
          },
          data => this.handleSmartFeedResponse(data, CreatorSmartFeedEngine),
          () => this.metadata.hasMore = false,
        );
      },
    },
  },
);

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.post-preview-action-container[data-v-672ddc34] {
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  width: 100%;
}
.post-preview-actions[data-v-672ddc34] {
  padding-top: var(--672ddc34-\\\$getStyles\\.paddingTop);
  gap: 8px;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/FanWeb/scripts/components/smart-feed/post/layouts/PostPreviewCardSmall.vue","webpack://./PostPreviewCardSmall.vue"],"names":[],"mappings":"AAGE;EACE,yCAAA;EACA,WAAA;ACFJ;ADKE;EACE,oDAAA;EACA,QAAA;EACA,mBAAA;ACHJ","sourcesContent":["@import \"@bloglovin/vue-component-library/breakpoint-mixin.scss\";\n\n.post-preview {\n  &-action-container {\n    border-top: 1px solid rgba(0, 0, 0, 0.15);\n    width: 100%;\n  }\n\n  &-actions {\n    padding-top: v-bind('$getStyles.paddingTop');\n    gap: 8px;\n    align-items: center;\n  }\n}\n",".post-preview-action-container {\n  border-top: 1px solid rgba(0, 0, 0, 0.15);\n  width: 100%;\n}\n.post-preview-actions {\n  padding-top: v-bind(\"$getStyles.paddingTop\");\n  gap: 8px;\n  align-items: center;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

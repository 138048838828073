<template>
  <component :is="component" v-if="component" :entity="smartFeedEntity" />
</template>

<script>
import { Enum } from "@bloglovin/vue-component-library";
import CreatorSmartFeedEngine from "@/classes/creator-smart-feed-engine";
import { markRaw } from "vue";

export default {
  name: "SmartFeedItem",
  props: {
    entityType: {
      type: String,
      required: true,
    },
    entityId: {
      type: [Number, String],
      required: true,
    },
    layout: {
      type: Enum,
      required: true,
      options: [...CreatorSmartFeedEngine.VALID_LAYOUT_TYPES],
    },
    // this is currently non-functional after refactor - @TODO - fix
    showUnknownEntitiesAsPlaceholder: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      component: null,
    };
  },
  computed: {
    smartFeedEntity() {
      const creatorSmartFeedEngine = new CreatorSmartFeedEngine();
      return creatorSmartFeedEngine.getEntity(this.entityType, this.entityId);
    },
  },
  async created() {
    const creatorSmartFeedEngine = new CreatorSmartFeedEngine();
    const layout = await creatorSmartFeedEngine.getLayoutComponentForEntity(this.entityType, this.layout);
    this.component = markRaw(layout.default);
  },
};

</script>

<style scoped lang="scss">

</style>

import { defineStore } from "pinia";
import { DEFAULT_ORG_LOGO } from "%/constants/publication-constants";
import { $axios } from "@bloglovin/vue-framework";
import { links } from "%/enums/link-enums";
import { useGeneralStore } from "%/stores/general-store";
import { useConfigurationStore } from "~/stores/configuration-store";
import { useLovedPostFeedStore } from "~/stores/smart-feed/feed/posts/loved-post-feed-store";
import { usePostFeedStore } from "~/stores/smart-feed/feed/posts/post-feed-store";
import FanWebSmartFeedEngine from "~/classes/fanweb-smart-feed-engine";
const DEFAULT_LAYOUT = "Blog";
export const usePublicationStore = defineStore("publication-store", {
  state: () => ({
    layoutName: "community",
    loading: false,
    posts: [],
    post: null,
    postViewType: "",
    publicationId: 0,
    publicationData: {},
    baseUri: "",
    stagingUri: "",
    productionUri: "",
    userDetailsChangeError: null,
    isLoading: false,
    loadingPosts: false,
    isVotingActionLoading: false,
    isLikeActionLoading: false,
    emailStatus: {
      requestedNewEmail: "",
      isEmailConfirmed: true,
      isEmailBeingChanged: false,
    },
    loginErrorMessage: "",
    signupErrorMessage: "",
    uploadingPostImage: false,
    updatePostIsLoading: false,
    canConfigurePublication: false,
  }),
  getters: {
    publicationName() {
      return this.publicationData.name;
    },
    appId() {
      return useGeneralStore().appId;
    },
    orgId() {
      return useGeneralStore().orgId;
    },
    isSubscribed() {
      return window.blclient.data.pageProps.subscribed;
    },
    usersApiV1() {
      return useGeneralStore().getLink("usersV1Api");
    },
    contentApiV1() {
      return useGeneralStore().getLink(links.contentApiV1);
    },
    creatorApiV1() {
      return useGeneralStore().getLink(links.api);
    },
    fanApiV1() {
      return useGeneralStore().getLink(links.fanApiV1);
    },
    fanUrl() {
      return useGeneralStore().fanUrl;
    },
    userData() {
      return useGeneralStore().userData;
    },
    userId() {
      return useGeneralStore().userId;
    },
    publicationLogo() {
      if (!this.publicationData.publication_logo) {
        return DEFAULT_ORG_LOGO;
      }
      return this.publicationData.publication_logo;
    },
    isUserLoggedIn() {
      return useConfigurationStore().isSignupAndLoginEnabled && this.userId > 0;
    },
  },
  actions: {
    setCanConfigurePublication(canConfigurePublication) {
      this.canConfigurePublication = canConfigurePublication;
    },
    updateTheme(theme, themeId, successHandler, errorHandler) {
      $axios.post(useGeneralStore().links.api + `/themes/org/${this.publicationData.org_id}/${themeId}/deploy/staging`, {
        theme: { ...theme },
      }, {
        headers: {
          Authorization: "",
        },
      }).then(successHandler).catch(errorHandler);
    },
    setBaseUri(baseUri, stagingUri, productionUri) {
      this.baseUri = baseUri;
      this.stagingUri = stagingUri;
      this.productionUri = productionUri;
    },
    setPublicationData(data) {
      this.publicationId = data.publicationId;
      this.publicationData = data.publication;
      this.layoutName = DEFAULT_LAYOUT;
    },
    setPublicationConfiguration(configuration) {
      this.publicationConfiguration = configuration;
    },
    async likePost(postId) {
      this.isLikeActionLoading = true;
      await this.apiPost(
        `/publication/post/${postId}/like`,
        {},
        response => {
          this.isLikeActionLoading = false;
          new FanWebSmartFeedEngine().addEntities(response.entities);
          useLovedPostFeedStore().reload();
        },
        () => {},
        () => {
          this.isLikeActionLoading = false;
        },
      );
    },
    async unlikePost(postId) {
      this.isLikeActionLoading = true;
      await this.apiPost(
        `/publication/post/${postId}/unlike`,
        {},
        response => {
          this.isLikeActionLoading = false;
          new FanWebSmartFeedEngine().addEntities(response.entities);
          useLovedPostFeedStore().reload();
        },
        () => {
          this.isLikeActionLoading = false;
        },
      );
    },
    async upVotePost(postId) {
      this.isVotingActionLoading = true;
      await this.apiPost(
        `/publication/post/${postId}/upvote`,
        {},
        response => {
          this.isVotingActionLoading = false;
          new FanWebSmartFeedEngine().addEntities(response.entities);
        },
        () => {},
        () => {
          this.isVotingActionLoading = false;
        },
      );
    },
    async downVotePost(postId) {
      this.isVotingActionLoading = true;
      await this.apiPost(
        `/publication/post/${postId}/downvote`,
        {},
        response => {
          this.isVotingActionLoading = false;
          new FanWebSmartFeedEngine().addEntities(response.entities);
        },
        () => {},
        () => {
          this.isVotingActionLoading = false;
        },
      );
    },
    removeVotePost(postId) {
      this.isVotingActionLoading = true;
      return this.apiPost(
        `/publication/post/${postId}/remove-vote`,
        {},
        response => {
          this.isVotingActionLoading = false;
          new FanWebSmartFeedEngine().addEntities(response.entities);
        },
        () => {},
        () => {
          this.isVotingActionLoading = false;
        },
      );
    },
    refreshPosts() {
      this.loadingPosts = true;
      this.apiGet(this.fanApiV1 + "/posts", { publication_id: this.publicationId }, posts => {
        this.posts = posts;
        this.loadingPosts = false;
      }, () => {
        this.loadingPosts = false;
      });
    },
    saveSessionDataAndRedirect(postData) {
      sessionStorage.setItem("postCreationData", JSON.stringify(postData));
      const encodedCurrentUri = encodeURIComponent(window.location.pathname);
      window.location.href = `${this.fanUrl}/login?redirect=${encodedCurrentUri}`;
      return;
    },
    uploadPostImage(selectedFile, postData) {
      if (!this.isUserLoggedIn) {
        this.saveSessionDataAndRedirect(postData);
      }
      this.uploadingPostImage = true;

      var formData = new FormData();
      formData.append("org_id", this.orgId);
      formData.append("publication_id", this.publicationId);
      formData.append("image", selectedFile);

      this.apiPostForm(`${this.contentApiV1}/post/image/upload`,
        formData
        , () => {
        },
        () => {},
        () => {
          this.uploadingPostImage = false;
        });
    },
    createPublicationPost(title, html, hashtags, callback) {
      if (!this.isUserLoggedIn) {
        this.saveSessionDataAndRedirect({
          title: title,
          html: html,
          hashtags: hashtags,
        });
      }
      this.updatePostIsLoading = true;
      const data = {
        publication_id: this.publicationId,
        org_id: this.orgId,
        post_title: title,
        post_content: html,
        post_hashtags: hashtags,
      };
      this.apiPost(`${this.creatorApiV1}/publication/${this.publicationId}/quick-post`, {
        data,
      }, () => {
        usePostFeedStore().reload();
        this.updatePostIsLoading = false;
      }, () => {
        this.updatePostIsLoading = false;
        callback(false);
      });
    },
  },
});

export default usePublicationStore;

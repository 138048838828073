import CreatorSmartFeedEngine from "@/classes/creator-smart-feed-engine";
import { links } from "%/enums/link-enums";
import { useGeneralStore } from "%/stores/general-store";
import { defineSmartFeedPiniaStore } from "@bloglovin/vue-framework";

export const useCompanyUsersFeedStore = defineSmartFeedPiniaStore(
  "company-users-feed-store",
  {
    state: () => ({
      entityType: CreatorSmartFeedEngine.USER,
      feed: [],
      metadata: {
        hasMore: true,
        lastId: 0,
        orgId: useGeneralStore().orgId,
      },
      smartFeedEngine: CreatorSmartFeedEngine,
      tableFields: [
        { key: "emailAddress", label: "Email", width: "30%" },
        { key: "fullName", label: "Name", width: "20%" },
        { key: "roleId", label: "Role" },
        { key: "status", label: "Status", width: "90px" },
        { key: "actions", label: "" },
      ],
      isLoading: false,
    }),
    getters: {
      usersApiV1() {
        return useGeneralStore().getLink(links.usersApiV1);
      },
      hasMore() {
        return this.metadata.hasMore;
      },
    },
    actions: {
      getSmartFeed() {
        this.apiGet(
          `${this.usersApiV1}/org/${this.metadata.orgId}/users/smart-feed`,
          {
            last_id: this.metadata.lastId,
          },
          data => {
            this.handleSmartFeedResponse(data, CreatorSmartFeedEngine);
          },
          () => {
            this.metadata.hasMore = false;
          },
        );
      },
    },
  },
);

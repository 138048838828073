import { defineStore } from "pinia";
import CreatorSmartFeedEngine from "@/classes/creator-smart-feed-engine";
import { useGeneralStore } from "%/stores/general-store";
import {
  useMyPublicationCollaboratorsFeedStore,
} from "@/stores/smart-feed/feed/use-my-publication-collaborators-feed-store";

export const useCollaborationStore = defineStore("collaboration-store", {
  state: () => ({
    collaborators: [],
    collaboratorApplications: [],
    collaboratorInvitations: [],
    orgCollaboratorApplications: [],
    changePublicationCollaboratorRoleSelectedOption: {},
    selectedCollaboratorForRoleChange: null,
    modals: {
      isInviteToPublicationModalVisible: false,
      isApplyToCollaborateModalVisible: false,
      isChangePublicationCollaboratorRoleModalVisible: false,
    },
    loading: {
      isApplyToCollaborateLoading: false,
      isPublicationApplicationsLoading: false,
      isApplicationActionLoading: false,
      isInvitationActionLoading: false,
      isCollaboratorActionLoading: false,
    },
    errors: {
      applyToCollaborateError: "",
      getPublicationApplicationsError: "",
      applicationActionError: "",
      invitationActionError: "",
      collaboratorActionError: "",
      changePublicationCollaboratorRoleErrorMessage: "",
    },
    pagination: {
      lastPublicationApplicationId: 0,
      lastPublicationCollaboratorId: 0,
      lastPublicationInvitationId: 0,
    },
    forms: {
      inviteToPublication: {
        onPlatform: {
          selectedRole: "",
          inviteMessage: "",
          selectedPublication: null,
        },
        offPlatform: {
          inviteName: "",
          inviteEmail: "",
          selectedRole: "",
          inviteMessage: "",
          selectedPublication: null,
        },
      },
      applyToCollaborate: {
        role: "",
        message: "",
      },
      validationsErrors: {
        inviteToPublication: {
          offPlatform: {
            inviteEmailError: "",
          },
        },
      },
    },
  }),
  getters: {
    inviteToPublicationModalState() {
      return this.modals.isInviteToPublicationModalVisible;
    },
    applyToCollaborateModalState() {
      return this.modals.isApplyToCollaborateModalVisible;
    },
    applyToCollaborateLoadingState() {
      return this.loading.isApplyToCollaborateLoading;
    },
    inviteToCollaborateLoadingState() {
      return this.loading.isInvitationActionLoading;
    },
    collaboratorActionLoadingState() {
      return this.loading.isCollaboratorActionLoading;
    },
    publicationApplicationsLoadingState() {
      return this.loading.isPublicationApplicationsLoading;
    },
    lastPublicationApplicationId() {
      return this.pagination.lastPublicationApplicationId;
    },
    activeCollaboratorApplications() {
      return this.collaboratorApplications.filter(
        application => application.status !== "application_accepted",
      );
    },
    inviteToPublicationFormData() {
      return this.forms.inviteToPublication;
    },
    applyToCollaborateFormData() {
      return this.forms.applyToCollaborate;
    },
    inviteToPublicationFormErrors() {
      return this.forms.validationsErrors.inviteToPublication;
    },
    activeCollaboratorInvitations() {
      return this.collaboratorInvitations.filter(
        invitation => invitation.status === "invited",
      );
    },
  },
  actions: {
    async applyToCollaborate(
      publicationId,
      orgId,
      email,
      name,
      role,
      owningOrgId,
    ) {
      this.loading.isApplyToCollaborateLoading = true;
      this.errors.applyToCollaborateError = "";

      await this.apiPost(
        `/collaboration/publication/${publicationId}/collaborator/apply`,
        {
          org_id: orgId,
          email: email,
          name: name,
          role: role,
          owning_org_id: owningOrgId,
        },
        () => {
          this.loading.isApplyToCollaborateLoading = false;
          this.closeApplyToCollaborateModalAndReset();
        },
        error => {
          this.loading.isApplyToCollaborateLoading = false;
          this.errors.applyToCollaborateError =
            error.response.data.message ?? "Unable to apply for collaboration";
        },
      );
    },
    async getPublicationApplications(publicationId) {
      this.loading.isPublicationApplicationsLoading = true;
      this.errors.getPublicationApplicationsError = "";

      await this.apiGet(
        `/collaboration/publication/${publicationId}/applications`,
        {
          last_id: this.pagination.lastPublicationApplicationId,
        },
        response => {
          this.loading.isPublicationApplicationsLoading = false;
          const newApplications = response.data.applications;

          if (newApplications.length > 0) {
            this.collaboratorApplications = [
              ...this.collaboratorApplications,
              ...newApplications,
            ];
            this.pagination.lastPublicationApplicationId =
              newApplications[
                  newApplications.length - 1
              ].publication_applicant_id;
          } else {
            this.pagination.lastPublicationApplicationId = -1;
          }

          return response.data;
        },
        () => {
          this.loading.isPublicationApplicationsLoading = false;
          this.errors.getPublicationApplicationsError =
            "Unable to get applications for publication";
        },
      );
    },
    async acceptCollaboratorApplication(publicationId, orgId, applicantId) {
      this.loading.isApplicationActionLoading = true;
      this.errors.applicationActionError = "";

      await this.apiPost(
        `/collaboration/publication/${publicationId}/collaborator/${orgId}/application/accept`,
        {
          publication_applicant_id: applicantId,
        },
        response => {
          this.loading.isApplicationActionLoading = false;
          new CreatorSmartFeedEngine().addEntities(response.entities);
        },
        () => {
          this.loading.isApplicationActionLoading = false;
          this.errors.applicationActionError =
            "Unable to accept collaboration application";
        },
      );
    },
    async declineCollaboratorApplication(publicationId, orgId, applicantId) {
      this.loading.isApplicationActionLoading = true;
      this.errors.applicationActionError = "";

      await this.apiPost(
        `/collaboration/publication/${publicationId}/collaborator/${orgId}/application/deny`,
        {
          publication_applicant_id: applicantId,
        },
        response => {
          this.loading.isApplicationActionLoading = false;
          new CreatorSmartFeedEngine().addEntities(response.entities);
        },
        () => {
          this.loading.isApplicationActionLoading = false;
          this.errors.applicationActionError =
            "Unable to decline collaboration application";
        },
      );
    },
    async revokeCollaboratorApplication(publicationId, orgId, applicantId) {
      this.loading.isApplicationActionLoading = true;
      this.errors.applicationActionError = "";

      await this.apiPost(
        `/collaboration/publication/${publicationId}/collaborator/${orgId}/application/revoke`,
        {
          publication_applicant_id: applicantId,
        },
        () => {
          this.loading.isApplicationActionLoading = false;
          const applicationIndex =
            this.getCollaboratorApplicationIndexById(applicantId);
          this.collaboratorApplications.splice(applicationIndex, 1);
        },
        () => {
          this.loading.isApplicationActionLoading = false;
          this.errors.applicationActionError =
            "Unable to revoke collaboration application";
        },
      );
    },
    async acceptOrgCollaboratorApplication(publicationId, orgId, applicantId) {
      this.loading.isApplicationActionLoading = true;
      this.errors.applicationActionError = "";

      await this.apiPost(
        `/collaboration/publication/${publicationId}/collaborator/${orgId}/application/accept`,
        {
          publication_applicant_id: applicantId,
        },
        response => {
          this.loading.isApplicationActionLoading = false;
          new CreatorSmartFeedEngine().addEntities(response.entities);
        },
        () => {
          this.loading.isApplicationActionLoading = false;
          this.errors.applicationActionError =
            "Unable to accept collaboration application";
        },
      );
    },
    async revokeOrgCollaboratorApplication(publicationId, orgId, applicantId) {
      this.loading.isApplicationActionLoading = true;
      this.errors.applicationActionError = "";

      await this.apiPost(
        `/collaboration/publication/${publicationId}/collaborator/${orgId}/application/revoke`,
        {
          publication_applicant_id: applicantId,
        },
        response => {
          this.loading.isApplicationActionLoading = false;
          new CreatorSmartFeedEngine().addEntities(response.entities);
        },
        () => {
          this.loading.isApplicationActionLoading = false;
          this.errors.applicationActionError =
            "Unable to revoke collaboration application";
        },
      );
    },
    async declineOrgCollaboratorApplication(publicationId, orgId, applicantId) {
      this.loading.isApplicationActionLoading = true;
      this.errors.applicationActionError = "";

      await this.apiPost(
        `/collaboration/publication/${publicationId}/collaborator/${orgId}/application/deny`,
        {
          publication_applicant_id: applicantId,
        },
        response => {
          this.loading.isApplicationActionLoading = false;
          new CreatorSmartFeedEngine().addEntities(response.entities);
        },
        () => {
          this.loading.isApplicationActionLoading = false;
          this.errors.applicationActionError =
            "Unable to decline collaboration application";
        },
      );
    },

    async inviteCollaboratorToPublication(publicationId, name, orgId, role) {
      this.loading.isInvitationActionLoading = true;
      this.errors.invitationActionError = "";

      await this.apiPost(
        `/collaboration/publication/${publicationId}/collaborator/invite`,
        {
          org_id: orgId,
          name,
          role,
        },
        () => {
          this.loading.isInvitationActionLoading = false;
          useMyPublicationCollaboratorsFeedStore().reload();
          this.closeInvitationModalAndReset();
        },
        error => {
          this.loading.isInvitationActionLoading = false;
          this.errors.invitationActionError =
            error.response.data.message ?? "Unable to invite collaborator";
        },
      );
    },
    async resendCollaboratorInvitationEmail(publicationId, userId) {
      this.loading.isInvitationActionLoading = true;
      this.errors.invitationActionError = "";

      const orgId = useGeneralStore().orgId;

      await this.apiPost(
        `/collaboration/publication/${publicationId}/collaborator/invite/resend`,
        {
          org_id: orgId,
          user_id: userId,
        },
        () => {
          this.loading.isInvitationActionLoading = false;
        },
        error => {
          this.loading.isInvitationActionLoading = false;
          this.errors.invitationActionError =
                error.response.data.message ?? "Unable to resend invitation email to collaborator";
        },
      );
    },
    async reactivateCollaboratorInvitation(publicationId, userId, applicationId) {
      this.loading.isInvitationActionLoading = true;
      this.errors.invitationActionError = "";

      const orgId = useGeneralStore().orgId;

      await this.apiPost(
        `/collaboration/publication/${publicationId}/collaborator/${orgId}/invitation/reactivate`,
        {
          publication_applicant_id: applicationId,
        },
        response => {
          new CreatorSmartFeedEngine().addEntities(response.entities);
          this.loading.isInvitationActionLoading = false;
        },
        error => {
          this.loading.isInvitationActionLoading = false;
          this.errors.invitationActionError =
                error.response.data.message ?? "Unable to reactivate invitation";
        },
      );
    },
    async inviteOffPlatformCollaboratorToPublication(
      publicationId,
      email,
      name,
      role,
      orgId,
    ) {
      this.loading.isInvitationActionLoading = true;
      this.errors.invitationActionError = "";
      await this.apiPost(
        `/collaboration/publication/${publicationId}/collaborator/invite/off-platform`,
        {
          email,
          name,
          role,
          org_id: orgId,
        },
        () => {
          this.loading.isInvitationActionLoading = false;
          useMyPublicationCollaboratorsFeedStore().reload();
          this.closeInvitationModalAndReset();
        },
        error => {
          this.loading.isInvitationActionLoading = false;
          this.errors.invitationActionError =
            error.response.data.message ?? "Unable to invite collaborator";
        },
      );
    },
    async acceptCollaboratorOrgInvitation(publicationId, orgId) {
      this.loading.isInvitationActionLoading = true;
      this.errors.invitationActionError = "";

      await this.apiPost(
        `/collaboration/publication/${publicationId}/collaborator/${orgId}/invitation/accept`,
        {},
        response => {
          this.loading.isInvitationActionLoading = false;
          const applicantData = response.invitationData;
          const applicationIndex = this.getCollaboratorInvitationIndexById(
            applicantData["publication_applicant_id"],
          );
          if (applicationIndex !== -1) {
            this.collaboratorInvitations.splice(applicationIndex, 1);
          }
        },
        () => {
          this.loading.isInvitationActionLoading = false;
          this.errors.invitationActionError =
            "Unable to accept collaboration invitation";
        },
      );
    },
    async acceptCollaboratorInvitation(publicationId, orgId) {
      this.loading.isInvitationActionLoading = true;
      this.errors.invitationActionError = "";

      await this.apiPost(
        `/collaboration/publication/${publicationId}/collaborator/${orgId}/invitation/accept`,
        {},
        response => {
          this.loading.isInvitationActionLoading = false;
          const applicantData = response.invitationData;
          const applicationIndex = this.getCollaboratorInvitationIndexById(
            applicantData["publication_applicant_id"],
          );
          this.collaboratorInvitations.splice(applicationIndex, 1);
        },
        () => {
          this.loading.isInvitationActionLoading = false;
          this.errors.invitationActionError =
            "Unable to accept collaboration invitation";
        },
      );
    },
    async declineCollaboratorInvitation(publicationId, orgId) {
      this.loading.isInvitationActionLoading = true;
      this.errors.invitationActionError = "";

      await this.apiPost(
        `/collaboration/publication/${publicationId}/collaborator/${orgId}/invitation/deny`,
        {},
        response => {
          this.loading.isInvitationActionLoading = false;
          const applicantData = response.invitationData;
          const applicationIndex = this.getCollaboratorInvitationIndexById(
            applicantData["publication_applicant_id"],
          );
          if (applicationIndex !== -1) {
            this.collaboratorInvitations.splice(applicationIndex, 1);
          }
        },
        () => {
          this.loading.isInvitationActionLoading = false;
          this.errors.invitationActionError =
            "Unable to decline collaboration invitation";
        },
      );
    },
    async revokeCollaboratorInvitation(publicationId, orgId) {
      this.loading.isInvitationActionLoading = true;
      this.errors.invitationActionError = "";

      await this.apiPost(
        `/collaboration/publication/${publicationId}/collaborator/${orgId}/invitation/revoke`,
        {},
        response => {
          this.loading.isInvitationActionLoading = false;
          new CreatorSmartFeedEngine().addEntities(response.entities);
        },
        () => {
          this.loading.isInvitationActionLoading = false;
          this.errors.invitationActionError =
            "Unable to revoke collaboration invitation";
        },
      );
    },
    async enableCollaborator(publicationId, orgId) {
      this.loading.isCollaboratorActionLoading = true;
      this.errors.collaboratorActionError = "";

      await this.apiPost(
        `/collaboration/publication/${publicationId}/collaborator/${orgId}/enable`,
        {},
        response => {
          new CreatorSmartFeedEngine().addEntities(response.entities);
          this.loading.isCollaboratorActionLoading = false;
        },
        () => {
          this.loading.isCollaboratorActionLoading = false;
          this.errors.collaboratorActionError =
                "Unable to enable collaborator";
        },
      );
    },
    async changeCollaboratorRole(publicationId, applicantId, role) {
      this.loading.isCollaboratorActionLoading = true;
      this.errors.collaboratorActionError = "";

      await this.apiPost(
        `/collaboration/publication/${publicationId}/collaborator/${applicantId}/role/change`,
        {
          "role": role,
        },
        response => {
          this.loading.isCollaboratorActionLoading = false;
          this.closeChangePublicationCollaboratorRoleModal();
          new CreatorSmartFeedEngine().addEntities(response.entities);
        },
        () => {
          this.loading.isCollaboratorActionLoading = false;
          this.errors.collaboratorActionError =
                "Unable to change collaborator role";
        },
      );
    },
    async disableCollaborator(publicationId, orgId) {
      this.loading.isCollaboratorActionLoading = true;
      this.errors.collaboratorActionError = "";

      await this.apiPost(
        `/collaboration/publication/${publicationId}/collaborator/${orgId}/disable`,
        {},
        response => {
          new CreatorSmartFeedEngine().addEntities(response.entities);
          this.loading.isCollaboratorActionLoading = false;
        },
        () => {
          this.loading.isCollaboratorActionLoading = false;
          this.errors.collaboratorActionError =
                "Unable to disable collaborator";
        },
      );
    },
    resetInviteToPublicationForm() {
      this.errors.invitationActionError = "";
      this.forms.validationsErrors.inviteToPublication.offPlatform = {
        inviteEmailError: "",
      };
      this.forms.inviteToPublication.onPlatform = {
        selectedRole: "",
        inviteMessage: "",
        selectedPublication: null,
      };
      this.forms.inviteToPublication.offPlatform = {
        inviteName: "",
        inviteEmail: "",
        selectedRole: "",
        inviteMessage: "",
        selectedPublication: null,
      };
    },
    closeInvitationModalAndReset() {
      this.modals.isInviteToPublicationModalVisible = false;
      this.resetInviteToPublicationForm();
    },
    openInviteToPublicationModel() {
      this.modals.isInviteToPublicationModalVisible = true;
    },
    openApplyToCollaborateModel() {
      this.modals.isApplyToCollaborateModalVisible = true;
    },
    resetApplyToCollaborateForm() {
      this.errors.applyToCollaborateError = "";
      this.forms.applyToCollaborate = {
        role: "",
        message: "",
      };
    },
    closeApplyToCollaborateModalAndReset() {
      this.modals.isApplyToCollaborateModalVisible = false;
      this.resetApplyToCollaborateForm();
    },
    getCollaboratorApplicationIndexById(applicantId) {
      return this.collaboratorApplications.findIndex(
        application => application.publication_applicant_id === applicantId,
      );
    },
    getCollaboratorInvitationIndexById(applicantId) {
      return this.collaboratorInvitations.findIndex(
        application => application.publication_applicant_id === applicantId,
      );
    },
    getOrgCollaboratorApplicationIndexById(applicantId) {
      return this.orgCollaboratorApplications.findIndex(
        application => application.publication_applicant_id === applicantId,
      );
    },
    getOrgCollaboratorInvitationIndexById(publicationId) {
      return Object.keys(this.collaboratorInvitations).find(
        key => this.collaboratorInvitations[key].publication_id === publicationId,
      );
    },
    openChangePublicationCollaboratorRoleModal(collaborator) {
      this.errors.changePublicationCollaboratorRoleErrorMessage = "";
      this.changePublicationCollaboratorRoleSelectedOption = {};
      this.selectedCollaboratorForRoleChange = collaborator;
      this.modals.isChangePublicationCollaboratorRoleModalVisible = true;
    },

    closeChangePublicationCollaboratorRoleModal() {
      this.modals.isChangePublicationCollaboratorRoleModalVisible = false;
      this.errors.changePublicationCollaboratorRoleErrorMessage = "";
      this.changePublicationCollaboratorRoleSelectedOption = {};
      this.selectedCollaboratorForRoleChange = null;
    },
    setChangePublicationCollaboratorRoleSelectedOption(newValue) {
      this.changePublicationCollaboratorRoleSelectedOption = newValue;
    },
  },
});

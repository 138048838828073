import CreatorSmartFeedEngine from "@/classes/creator-smart-feed-engine";
import { defineSmartFeedPiniaStore } from "@bloglovin/vue-framework";

export const useHomeCommunityPostFeedStore = defineSmartFeedPiniaStore("home-community-post-feed-store", {
  state: () => ({
    feed: [],
    metadata: {
      hasMore: true,
      lastId: Number.MAX_SAFE_INTEGER,
    },
    isLoading: false,
    smartFeedEngine: CreatorSmartFeedEngine,
  }),
  getters: {
    hasMore() {
      return this.metadata.hasMore;
    },
  },
  actions: {
    getSmartFeed() {
      this.apiGet(
        "/posts/home/smart-feed",
        {
          last_id: this.metadata.lastId,
        },
        data => this.handleSmartFeedResponse(data, CreatorSmartFeedEngine),
        () => {
          this.metadata.hasMore = false;
          // eslint-disable-next-line @stylistic/js/comma-dangle
        }
      );
    },
  },
});

import { defineFeedEntityPiniaStore } from "@bloglovin/vue-framework";

export const useFeedGroupBlogEntityStore = defineFeedEntityPiniaStore(
  "feed-group-blog-entity-store",
  {
    state: () => ({}),
    getters: {},
    actions: {},
  },
);

<template>
  <PageWrapper
    :active-section="SECTION_HOME"
    :active-page="PAGE_HOME"
    align="left"
    width="full"
  >
    <SmartFeed :pinia-store="useHomeCommunityPostFeedStore" default-layout="masonry" />
  </PageWrapper>
</template>

<script>
import PageWrapper from "@/components/PageWrapper";
import { SECTION_HOME, PAGE_HOME } from "@/constants/nav-constants";
import SmartFeed from "@/components/smart-feed/SmartFeed.vue";
import { useHomeCommunityPostFeedStore } from "@/stores/smart-feed/feed/posts/home-community-post-feed-store";
export default {
  name: "Home",
  components: {
    PageWrapper,
    SmartFeed,
  },
  data() {
    return {
      SECTION_HOME,
      PAGE_HOME,
    };
  },
  methods: {
    useHomeCommunityPostFeedStore,
  },
};
</script>


import { useGeneralStore } from "%/stores/general-store";
import CreatorSmartFeedEngine from "@/classes/creator-smart-feed-engine";
import { defineSmartFeedPiniaStore } from "@bloglovin/vue-framework";

export const useOrgPostFeedStore = defineSmartFeedPiniaStore("org-post-feed-store", {
  state: () => ({
    entityType: CreatorSmartFeedEngine.MY_POST,
    feed: [],
    metadata: {
      hasMore: true,
      lastId: Number.MAX_SAFE_INTEGER,
      orgId: useGeneralStore().orgId,
    },
    smartFeedEngine: CreatorSmartFeedEngine,
    isLoading: false,
    tableFields: [
      { key: "postTitle", label: "Title" },
      { key: "publicationName", label: "Publication" },
      { key: "status", label: "Status" },
      { key: "variants", label: "Variants" },
      { key: "timestampUpdated", label: "Edited" },
      { key: "timestampCreated", label: "Created" },
      { key: "actions", label: "" },
    ],
  }),
  getters: {
    hasMore() {
      return this.metadata.hasMore;
    },
  },
  actions: {
    getSmartFeed() {
      this.apiGet(
        `/org/${this.metadata.orgId}/post/smart-feed`,
        {
          last_id: this.metadata.lastId,
        },
        data => this.handleSmartFeedResponse(data, CreatorSmartFeedEngine),
        () => this.metadata.hasMore = false,
      );
    },
  },
});

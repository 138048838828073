import CreatorSmartFeedEngine from "@/classes/creator-smart-feed-engine";
import { defineSmartFeedPiniaStore } from "@bloglovin/vue-framework";

export const useFeedGroupBlogFeedStore = defineSmartFeedPiniaStore("feed-group-blog-feed-store", {
  state: () => ({
    feed: [],
    metadata: {
      hasMore: true,
      lastId: 0,
      feedGroupId: 0,
    },
    isLoading: false,
  }),
  getters: {
    hasMore() {
      return this.metadata.hasMore;
    },
  },
  actions: {
    getSmartFeed() {
      this.apiGet(
        `/feeds/feed-group/${this.metadata.feedGroupId}/blogs/smart-feed`,
        {
          last_id: this.metadata.lastId,
        },
        data => this.handleSmartFeedResponse(data, CreatorSmartFeedEngine),
      );
    },
  },
});

<template>
  <PageWrapper
    :active-section="SECTION_PUBLICATIONS"
    :active-page="PAGE_PUBLICATION_FEED_GROUP_LIST"
    title="RSS Content"
    :breadcrumbs="breadcrumbs"
    page-name="RSS Content"
    align="left"
    width="full"
  >
    <iRow width="fill" align="right">
      <iButton @click="openCreateFeedModal">
        Create New Feed
      </iButton>
    </iRow>
    <SmartFeed :pinia-store="useFeedGroupFeedStore" default-layout="small-card" />
    <iModal
      :visible="isCreateFeedModalOpen"
      title="Create New Feed"
      :is-loading="isLoadingCreateFeedGroup"
      @close="closeCreateFeedModal"
      @click:primary="handleCreateFeedGroup"
    >
      <template #body>
        <iRow>
          <iTextInput
            v-model="feedName"
            label="feed name"
            placeholder="eg. Spring Fashion"
          />
        </iRow>
      </template>
    </iModal>
  </PageWrapper>
</template>

<script>
import { mapActions, mapState } from "pinia";
import PageWrapper from "@/components/PageWrapper";
import {
  PAGE_PUBLICATION_FEED_GROUP_LIST,
  SECTION_PUBLICATIONS,
} from "@/constants/nav-constants";
import { usePublicationStore } from "@/stores/publication-store";
import { useFeedStore } from "@/stores/feed-store";
import { useFeedGroupFeedStore } from "@/stores/smart-feed/feed/feed-group-feed-store";
import SmartFeed from "@/components/smart-feed/SmartFeed";
export default {
  name: "PublicationFeedGroupList",
  components: {
    PageWrapper,
    SmartFeed,
  },
  data() {
    return {
      PAGE_PUBLICATION_FEED_GROUP_LIST,
      SECTION_PUBLICATIONS,
      isCreateFeedModalOpen: false,
      feedName: "",
    };
  },
  computed: {
    ...mapState(usePublicationStore, ["publicationId", "orgId"]),
    ...mapState(useFeedStore, ["isLoadingCreateFeedGroup"]),
    breadcrumbs() {
      return [
        { url: "/", label: "Home" },
        { url: `/publication/org/${this.orgId}`, label: "My Publications" },
        { url: `/publication/${this.publicationId}`, label: "Overview" },
      ];
    },
  },
  methods: {
    useFeedGroupFeedStore,
    ...mapActions(useFeedStore, ["createFeedGroup"]),
    openCreateFeedModal() {
      this.isCreateFeedModalOpen = true;
    },
    closeCreateFeedModal() {
      this.isCreateFeedModalOpen = false;
    },
    handleCreateFeedGroup() {
      this.createFeedGroup(this.feedName, () => {
        this.closeCreateFeedModal();
      });
    },
  },
};
</script>

<style scoped>
</style>

<template>
  <SmartFeedTableRow :entity="entity" :fields="fields">
    <template #td(recurring)>
      {{ isRecurringPlan ? "Recurring" : "Initial" }}
    </template>
    <template #td(planName)>
      {{ entity.planName }}
    </template>
    <template #td(datePaid)>
      {{ formattedDate(entity.datePaid) }}
    </template>
    <template #td(amountPaid)>
      {{ getCurrencySymbol(entity.planCurrencyCode) }}{{ entity.amountPaid }}
    </template>
    <template #td(status)>
      {{ entity.status }}
    </template>
    <template #td(actions)>
      <iButton
        v-if="entity.status === 'processing'"
        :is-loading="isLoading"
        size="small"
        @click="handlePayment"
      >
        Pay
      </iButton>
    </template>
  </SmartFeedTableRow>
</template>

<script>
import { mapActions, mapState } from "pinia";
import SmartFeedTableRow from "~/components/smart-feed/SmartFeedTableRow";
import { MAPPED_CURRENCY_CODES } from "~/constants/currency-constants";
import { timeAgo } from "~/helpers/publication-helper";
import { useSubscriberBillingHistoryEntityStore } from "~/stores/smart-feed/entity/subscriber-billing-history-entity-store";
import { useSubscriberStore } from "~/stores/subscriber-store";

export default {
  name: "SubscriberBillingHistoryTableRow",
  components: { SmartFeedTableRow },
  props: {
    entity: {
      type: Object,
      required: true,
    },
    fields: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapState(useSubscriberBillingHistoryEntityStore, ["entities"]),
    ...mapState(useSubscriberStore, ["isLoading"]),
    isRecurringPlan() {
      const entityIds = Object.keys(this.entities);
      const currentIndex = entityIds.indexOf(
        this.entity.subscriberBillingHistoryId,
      );
      if (currentIndex > 0) {
        const previousEntity = this.entities[entityIds[currentIndex - 1]];
        return previousEntity.planName === this.entity.planName;
      }
      return false;
    },
  },
  methods: {
    ...mapActions(useSubscriberStore, [
      "createCheckoutSessionForExistingSubscriber",
    ]),
    formattedDate(timestamp) {
      return timeAgo(timestamp);
    },
    getCurrencySymbol(currencyCode) {
      const currency = MAPPED_CURRENCY_CODES.find(
        code => code.key === currencyCode,
      );
      return currency ? currency.symbol : "";
    },
    async handlePayment() {
      await this.createCheckoutSessionForExistingSubscriber(
        this.entity.subscriberId,
        this.entity.subscriberBillingHistoryId,
      );
    },
  },
};
</script>

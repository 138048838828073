<template>
  <PageWrapper
    :active-section="SECTION_COMMUNITY"
    :active-page="PAGE_COMMUNITY_CREATORS"
    title="Creators"
    sub-title=""
    :breadcrumbs="breadcrumbs"
    page-name="Creators"
    align="center"
    width="full"
  >
    <SmartFeed
      :pinia-store="useCreatorDirectoryFeedStore"
      default-layout="small-card"
    />
  </PageWrapper>
</template>

<script>
import PageWrapper from "@/components/PageWrapper";
import SmartFeed from "@/components/smart-feed/SmartFeed";
import {
  PAGE_COMMUNITY_CREATORS,
  SECTION_COMMUNITY,
} from "@/constants/nav-constants";
import { LAYOUT_CARD_SMALL } from "@/constants/smart-feed-constants";
import { useCreatorDirectoryFeedStore } from "@/stores/smart-feed/feed/creator-directory-feed-store";

export default {
  name: "CreatorsDirectory",
  components: {
    SmartFeed,
    PageWrapper,
  },
  data() {
    return {
      SECTION_COMMUNITY,
      PAGE_COMMUNITY_CREATORS,
      LAYOUT_CARD_SMALL,
    };
  },
  computed: {
    breadcrumbs() {
      return [
        { url: "/", label: "Home" },
        { url: "/community", label: "Community" },
      ];
    },
  },
  methods: {
    useCreatorDirectoryFeedStore,
  },
};
</script>
